import * as React from "react";

const SHOW_CONFIRM = "SHOW_CONFIRM";
const HIDE_CONFIRM = "HIDE_CONFIRM";

type StateType = {
  show: boolean;
  text: string;
};

const initialState: StateType = {
  show: false,
  text: "",
};

const ConfirmContext = React.createContext(
  null as [StateType, React.Dispatch<any>] | null
);

const reducer = (
  state = initialState,
  action: {
    type: string;
    payload: {
      show: boolean;
      text: string;
    };
  }
) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        text: action.payload.text,
      };
    case HIDE_CONFIRM:
      return initialState;
    default:
      return initialState;
  }
};

const ConfirmContextProvider = ({
  children,
}: {
  children?: React.ReactChild | React.ReactChild[];
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ConfirmContext.Provider value={[state, dispatch]}>
      {children}
    </ConfirmContext.Provider>
  );
};

let resolveCallback: (value: boolean) => void;
function useConfirm() {
  const [confirmState, dispatch] = React.useContext(ConfirmContext) as [
    StateType,
    React.Dispatch<any>
  ];
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = (text: string) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        text,
      },
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM,
    });
  };

  return { confirm, onConfirm, onCancel, confirmState };
}

export { useConfirm, ConfirmContextProvider };
