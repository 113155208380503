import * as React from "react";
import { useAuth } from "../hooks/useAuth";
import { Route, Redirect, useParams } from "react-router-dom";
import useGetJobList from "../components/Dashboard/DashboardApi";
import { useTitle } from "../hooks/useTitle";
import { IJobData } from "../types/Types";

export default function SuperAdminRoute({
  C,
  ...rest
}: {
  path: string;
  exact: boolean;
  C: any;
  cmpny?: any;
  jobData?: IJobData;
}) {
  let { isAuthenticated, authJobs } = useAuth();
  const { data } = useGetJobList();
  const jobData: IJobData = data?.jobData || {};

  return (
    <Route
      {...rest}
      render={({ ...props }) => {
        if (
          isAuthenticated &&
          authJobs.includes(props.match.params.selectedJob as string)
        ) {
          return (
            <ProjectContainer jobData={jobData}>
              <C {...props} {...rest} />
            </ProjectContainer>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

function ProjectContainer({ children, jobData }: ContainerProps) {
  const { setTitle } = useTitle();
  const { selectedJob }: { selectedJob: string } = useParams();
  React.useEffect(() => {
    if (jobData) {
      setTitle(jobData[selectedJob]?.job_name || "");
    }
  }, [setTitle, selectedJob, jobData]);
  return <div>{children}</div>;
}

type ContainerProps = {
  children: React.ReactNode;
  jobData: IJobData;
};
