import { Button, ButtonGroup, Dialog, DialogTitle, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useConfirm } from "../hooks/useConfirmDialog";

function ConfirmComponent() {
  const { onConfirm, onCancel, confirmState } = useConfirm();

  return confirmState.show ? (
    <Dialog open={confirmState.show}>
      <Box sx={{ p: 2 }}>
        <DialogTitle sx={{ whiteSpace: "pre-line" }}>
          {confirmState.text}
        </DialogTitle>
        <Stack direction="row" spacing={4} sx={{ pl: 4 }}>
          <Button variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="contained" color="error" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Dialog>
  ) : null;
}

export default ConfirmComponent;
