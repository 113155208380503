const dev = {
  s3: {
    REGION: "YOUR_DEV_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://test.murnanepm.com/dev/",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_vxKhGBzP6",
    APP_CLIENT_ID: "6utjjh8p7qh0e7avca7njvae8t",
    //IDENTITY_POOL_ID: "us-east-1:4a5fa44c-c4db-406b-9a06-03135c6c0330",
  },
};

const prod = {
  s3: {
    REGION: "YOUR_PROD_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_PROD_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.murnanepm.com/prod/",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_wEPaalrOL",
    APP_CLIENT_ID: "6imopbssorf2o3mkvoeskm17n1",
    //IDENTITY_POOL_ID: "us-east-1:dd8a3050-aefd-4cc3-ab06-500acff88097",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

const configSettings = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};

export default configSettings;
