import { API } from "aws-amplify";
import * as React from "react";
import { useQuery } from "react-query";
import { JobListType } from "../types/Types";
import { useAuth } from "./useAuth";

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

const userContext = React.createContext(
  {} as { userInfo: JobListType | undefined; isFetching: boolean }
);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideUserContext({ children }: Props) {
  const userInfo = useProvideUserInfo();
  return (
    <userContext.Provider value={userInfo}>{children}</userContext.Provider>
  );
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useUserInfo = () => {
  return React.useContext(userContext);
};

const getJobList: () => Promise<JobListType> = async () => {
  return await API.get("time", "login", {});
};

// Provider hook that creates auth object and handles state
function useProvideUserInfo() {
  const { isAuthenticated } = useAuth();

  const { data: userInfo, isFetching } = useQuery(
    ["jobs"],
    () => getJobList(),
    {
      staleTime: 300000,
      enabled: isAuthenticated,
    }
  );

  React.useEffect(() => {
    if (isAuthenticated && userInfo) {
      try {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
      } catch (error) {
        console.log(error);
        localStorage.clear();
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
      }
    } else {
      localStorage.removeItem("userInfo");
    }
  }, [isAuthenticated, userInfo]);

  // Return the user object and auth methods
  return { userInfo, isFetching };
}
