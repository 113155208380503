import * as React from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";

type ITitleContext = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

const titleContext = React.createContext({ title: "" } as ITitleContext);

export function ProvideTitle({ children }: Props) {
  const title = useProvideTitle();

  return (
    <titleContext.Provider value={title}>{children}</titleContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useTitle = () => {
  return React.useContext(titleContext);
};
// Provider hook that creates auth object and handles state
function useProvideTitle() {
  const localState = localStorage.getItem("title") || "";
  const [title, setTitle] = React.useState(localState);
  const { isAuthenticated } = useAuth();

  let location = useLocation();

  React.useEffect(() => {
    switch (location.pathname) {
      case "/":
        isAuthenticated && setTitle("Dashboard");
        !isAuthenticated && setTitle("");
        break;
      case "/admin":
        isAuthenticated && setTitle("Admin");
        break;
      case "/login":
        isAuthenticated && setTitle("Admin");
        break;
      default:
        return;
    }
  }, [location, isAuthenticated]);

  React.useEffect(() => {
    try {
      localStorage.setItem("title", title);
    } catch (error) {
      console.log(error);
      localStorage.clear();
      localStorage.setItem("title", title);
    }

    return () => {
      localStorage.removeItem("title");
    };
  }, [title]);
  return {
    title,
    setTitle,
  };
}
