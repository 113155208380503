import * as React from "react";
import { useAuth } from "../hooks/useAuth";
import { Route, Redirect } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import { useUserInfo } from "../hooks/useUserInfo";
import LoadingComponent from "../utils/LoadingComponent";

export default function AdminRoute({
  C,
  ...rest
}: {
  path: string;
  exact?: boolean;
  C: any;
}) {
  let { isAuthenticated } = useAuth();
  let { userInfo } = useUserInfo();
  const { setTitle } = useTitle();
  React.useEffect(() => {
    setTitle("Admin");
  }, [setTitle]);

  return (
    <Route
      {...rest}
      render={({ ...props }) => {
        return userInfo?.permissions ? (
          isAuthenticated &&
          Object.values(userInfo.permissions).includes("w") ? (
            <C {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <LoadingComponent />
        );
      }}
    />
  );
}
