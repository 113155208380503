import { useQuery } from "react-query";
import { API } from "aws-amplify";
import { JobListType } from "../../types/Types";

const getJobList: () => Promise<JobListType> = async () => {
  return await API.get("time", "login", {});
};

export default function useGetJobList() {
  return useQuery(["jobs"], () => getJobList(), {
    staleTime: 300000,
  });
}
