import Box from "@mui/material/Box";
import React, { lazy, Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainView from "./components/MainView";
import Menu from "./components/Menu";
import LoginButton from "./components/MenuComponents/LoginButton";
import LogoutAdminButton from "./components/MenuComponents/LogoutAdminButton";
import { useAuth } from "./hooks/useAuth";
import { ConfirmContextProvider } from "./hooks/useConfirmDialog";
import { useTitle } from "./hooks/useTitle";
import { ProvideUserContext } from "./hooks/useUserInfo";
import AdminRoute from "./routes/AdminRoute";
import ProjectRoute from "./routes/ProjectRoute";
import { IDashboardOptions } from "./types/Types";
import ConfirmComponent from "./utils/ConfirmComponent";
import LoadingComponent from "./utils/LoadingComponent";

const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const ProjectsContainer = lazy(() => import("./components/Projects/Projects"));
const ProjectSettingsContainer = lazy(
  () => import("./components/ProjectSettings/ProjectSettings")
);
const ProjectDownloadsContainer = lazy(
  () => import("./components/ProjectDownloads/ProjectDownloads")
);
const LoginContainer = lazy(() => import("./components/Login/Login"));
const TimeReportContainer = lazy(
  () => import("./components/TimeReport/MainTimeReport")
);
const AdminContainer = lazy(() => import("./components/Admin/Admin"));
const TicketsContainer = lazy(() => import("./components/Tickets/Tickets"));

export default function App() {
  const [open, setOpen] = useState<boolean>(false);
  const [dashbordComponent, setDashboardComponent] =
    useState<IDashboardOptions>("Projects");
  const { title, setTitle } = useTitle();
  const { isAuthenticated } = useAuth();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ProvideUserContext>
      <ConfirmContextProvider>
        <ConfirmComponent />
        <Box sx={{ display: "flex" }}>
          <Menu
            open={open}
            setOpen={setOpen}
            title={title}
            setMenuTitle={setTitle}
            dashboardComponent={dashbordComponent}
            setDashboardComponent={setDashboardComponent}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          >
            {isAuthenticated && <LogoutAdminButton setMenuTitle={setTitle} />}
            {!isAuthenticated && <LoginButton setMenuTitle={setTitle} />}
          </Menu>
          <MainView open={open}>
            <Suspense fallback={<LoadingComponent />}>
              <Switch>
                {!isAuthenticated && (
                  <Route path="/" exact component={LandingPlaceholder} />
                )}
                {isAuthenticated && (
                  <Route path="/" exact component={Dashboard} />
                )}
                <Route path="/login" component={LoginContainer} />
                <AdminRoute path="/admin" C={AdminContainer} />
                <ProjectRoute
                  path="/:selectedJob"
                  exact
                  C={ProjectsContainer}
                />
                <ProjectRoute
                  path="/:selectedJob/settings"
                  exact
                  C={ProjectSettingsContainer}
                />
                <ProjectRoute
                  path="/:selectedJob/downloads"
                  exact
                  C={ProjectDownloadsContainer}
                />
                <ProjectRoute
                  exact={false}
                  path="/:selectedJob/tickets"
                  C={TicketsContainer}
                />
                <ProjectRoute
                  exact
                  path="/:selectedJob/time/:selectedDay"
                  C={TimeReportContainer}
                />
                <Route component={NotFound} />
              </Switch>
              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
              />
            </Suspense>
          </MainView>
        </Box>
      </ConfirmContextProvider>
    </ProvideUserContext>
  );
}

function LandingPlaceholder() {
  return (
    <div style={{ padding: "80px 0", textAlign: "center" }}>
      <div>
        <h1 style={{ fontFamily: "Open Sans", fontWeight: 600 }}>
          Murnane Timekeeper
        </h1>
        <p style={{ color: "#999" }}>
          Daily Report Application for Contractors
        </p>
      </div>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      <h3 style={{ paddingTop: "100px", textAlign: "center" }}>
        Sorry, page not found!
      </h3>
    </div>
  );
}

export type MenuProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  setMenuTitle: React.Dispatch<React.SetStateAction<string>>;
  dashboardComponent: IDashboardOptions;
  setDashboardComponent: React.Dispatch<
    React.SetStateAction<IDashboardOptions>
  >;
  children?: React.ReactNode;
};

export type MenuChildrenProps = {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  handleDrawerOpen?: () => void;
  handleDrawerClose?: () => void;
  setMenuTitle?: React.Dispatch<React.SetStateAction<string>>;
  dashboardComponent?: IDashboardOptions;
  setDashboardComponent?: React.Dispatch<
    React.SetStateAction<IDashboardOptions>
  >;
  children?: React.ReactNode;
};

export type MenuButtonProps = {
  setMenuTitle: React.Dispatch<React.SetStateAction<string>>;
};
