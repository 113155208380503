import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import { useUserInfo } from "../../hooks/useUserInfo";
import { MenuButtonProps } from "../../App";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LogoutMenu({ setMenuTitle }: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isLargeScreen = useMediaQuery("(min-width:600px)");
  const { signout, user } = useAuth();
  const { userInfo } = useUserInfo();
  const isAdmin = Object.values(userInfo?.permissions || []).includes("w");
  const history = useHistory();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return !isLargeScreen ? (
    <div>
      <IconButton
        style={{ color: "white" }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isAdmin && (
          <MenuItem
            color="inherit"
            onClick={() => {
              setMenuTitle("Admin");
              history.push("/admin");
              handleClose();
            }}
          >
            Admin
          </MenuItem>
        )}
        <MenuItem
          onClick={async () => {
            signout();
          }}
          color="inherit"
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  ) : (
    <React.Fragment>
      {isAdmin && (
        <Button
          color="inherit"
          onClick={() => {
            setMenuTitle("Admin");
            history.push("/admin");
            handleClose();
          }}
        >
          Admin
        </Button>
      )}
      <Button
        onClick={() => {
          setMenuTitle("");
          signout();
          history.push("/");
        }}
        color="inherit"
      >
        Logout
      </Button>
    </React.Fragment>
  );
}
