import { Auth } from "aws-amplify";
import * as React from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { CognitoUserResponse, IAuthContext, IUserInfo } from "../types/Types";

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

const ONE_WEEK = 60 * 60 * 24 * 7;

const authContext = React.createContext({} as IAuthContext);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: Props) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return React.useContext(authContext);
};

const storedAuth: boolean = JSON.parse(
  localStorage.getItem("authed") || "false"
);
const storedUser: IUserInfo | null = JSON.parse(
  localStorage.getItem("user") || "null"
);

const storedAuthJobs: string[] = JSON.parse(
  localStorage.getItem("jobs") || "[]"
);

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = React.useState<IUserInfo | null>(storedUser);
  const [authJobs, setAuthJobs] = React.useState<string[]>(storedAuthJobs);
  const [isAuthenticated, setIsAuthenticated] =
    React.useState<boolean>(storedAuth);
  let history = useHistory();
  let queryClient = useQueryClient();

  //NOTE: SIGNIN IS CONTROLLED IN LOGIN COMPONENT, INSTEAD PASS SETUSER AS A PROP.

  const signout = async () => {
    try {
      await Auth.signOut({ global: true });
      localStorage.removeItem("title");
      localStorage.removeItem("authed");
      localStorage.removeItem("jobs");
      localStorage.removeItem("user");
      localStorage.removeItem("userinfo");
      setIsAuthenticated(false);
      setUser(null);
      queryClient.removeQueries();
      queryClient.clear();
      history.push("/login");
    } catch (error) {
      setIsAuthenticated(false);
      localStorage.removeItem("title");
      localStorage.removeItem("authed");
      localStorage.removeItem("jobs");
      localStorage.removeItem("user");
      localStorage.removeItem("userinfo");
      queryClient.removeQueries();
      queryClient.clear();
      history.push("/login");
      console.log("error signing out: ", error);
    }
  };

  React.useEffect(() => {
    try {
      localStorage.setItem("authed", `${isAuthenticated}`);
    } catch (error) {
      console.log(error);
      localStorage.clear();
      localStorage.setItem("authed", `${isAuthenticated}`);
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (isAuthenticated) {
      try {
        localStorage.setItem("user", JSON.stringify(user));
      } catch (error) {
        console.log(error);
        localStorage.clear();
        localStorage.setItem("user", JSON.stringify(user));
      }
    }
  }, [user, isAuthenticated]);

  React.useEffect(() => {
    if (isAuthenticated) {
      try {
        localStorage.setItem("jobs", JSON.stringify(authJobs));
      } catch (error) {
        console.log(error);
        localStorage.clear();
        localStorage.setItem("jobs", JSON.stringify(authJobs));
      }
    }
  }, [authJobs, isAuthenticated]);

  React.useEffect(() => {
    //check login time and logout if greater than ONE_WEEK in Epoch
    async function checkAuth() {
      try {
        let testDate = new Date().getTime() / 1000;
        //Auth.currentSession will throw error if no session, so catch block contains logic to clear isAuthenticated, setUser, and setTitle
        const currentSession = await Auth.currentSession();
        const user: CognitoUserResponse = currentSession
          ? await Auth.currentUserInfo()
          : null;

        if (
          currentSession &&
          testDate - currentSession?.getIdToken()?.payload.auth_time < ONE_WEEK
        ) {
          try {
            localStorage.setItem("authed", "true");
            localStorage.setItem("user", JSON.stringify(user));
          } catch (error) {
            console.log(error);
            localStorage.clear();
            localStorage.setItem("authed", "true");
            localStorage.setItem("user", JSON.stringify(user));
          }
          if (user) {
            const { id, username } = user;
            setUser({
              region: user.attributes["custom:region"],
              cmpny: user.attributes["custom:cmpny"],
              role: user.attributes["custom:role"],
              sub: user.attributes.sub,
              id,
              username,
            });
          }
          setIsAuthenticated(true);
          //Need to set this because typing a route refreshes the page, which doesn't keep user and isAuthenticated.
          return;
        } else {
          await Auth.signOut({ global: true });
          localStorage.removeItem("authed");
          localStorage.removeItem("jobs");
          localStorage.removeItem("user");
          localStorage.removeItem("userinfo");
          queryClient.clear();
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (e) {
        localStorage.removeItem("authed");
        localStorage.removeItem("jobs");
        localStorage.removeItem("user");
        localStorage.removeItem("userinfo");
        queryClient.clear();
        setIsAuthenticated(false);
        setUser(null);
        if (e !== "No current user") {
          console.log(e);
        }
        return;
      }
    }
    checkAuth();
  }, [isAuthenticated, queryClient]);
  // Return the user object and auth methods
  return {
    user,
    isAuthenticated,
    authJobs,
    setAuthJobs,
    setIsAuthenticated,
    setUser,
    signout,
  };
}
