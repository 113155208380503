import { useCallback, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { MenuProps } from "../App";
import { ReactComponent as MBCSvg } from "../svg/mbcicon.svg";
import SvgIcon from "@mui/material/SvgIcon";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Typography from "@mui/material/Typography";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { JobListType } from "../types/Types";
import { useUserInfo } from "../hooks/useUserInfo";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Menu({
  open,
  setOpen,
  handleDrawerOpen,
  handleDrawerClose,
  title,
  setMenuTitle,
  children,
}: MenuProps) {
  const theme = useTheme();
  let history = useHistory();
  let location = useLocation();
  const { userInfo: user } = useUserInfo();
  const cmpny = user?.cmpny || "";
  const { isAuthenticated } = useAuth();

  const { menuList, path, adminPath, basePath } = getMenuItems(
    location,
    user,
    cmpny
  );

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    ref,
    useCallback(() => setOpen!(false), [setOpen])
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {isAuthenticated && (path || adminPath) && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            aria-label="home"
            onClick={(e) => {
              isAuthenticated && setMenuTitle!("Dashboard");
              history.push("/");
            }}
            size="large"
          >
            <SvgIcon
              sx={{
                logoStyle: {
                  transform: "scale(1.3)",
                  "& g": {
                    color: "white",
                  },
                },
              }}
            >
              <MBCSvg />
            </SvgIcon>
          </IconButton>
          <Typography
            style={{ fontSize: 15, padding: "10px" }}
            variant="h6"
            noWrap
            component="div"
          >
            {title}
          </Typography>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {children}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        ref={ref}
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuList.map((text, index) => (
            <ListItem
              button
              onClick={() => {
                history.push("/" + basePath + menuPaths[text]);
                handleDrawerClose!();
              }}
              key={text}
            >
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}

type Location = {
  pathname: string;
};

function getMenuItems(
  loc: Location,
  user: JobListType | undefined,
  cmpny: string
) {
  const path = matchPath(loc.pathname, {
    path: "/:selectedJob",
    strict: true,
  });

  const jobParam: string | undefined =
    //@ts-ignore
    path?.params?.["selectedJob"] || undefined;
  const homePath = matchPath(loc.pathname, {
    path: "/",
    strict: true,
  });
  const adminPath = matchPath(loc.pathname, {
    path: "/admin",
  });
  const basePath = loc.pathname.split("/")[1];

  let menuList = [] as string[] | [];

  if (user?.permissions || jobParam) {
    menuList = [
      ...(path && !adminPath && jobParam
        ? [
            ...["Time and Reports", "Tickets"],
            ...((user?.jobData?.[jobParam]?.access?.[cmpny]?.settings && [
              "Settings",
              "Downloads",
            ]) ||
              []),
          ]
        : []),
      ...(adminPath && user?.permissions
        ? [
            ...((user.permissions.emp === "w" && ["Employees"]) || []),
            ...((user.permissions.codes === "w" && ["Cost Codes"]) || []),
            ...(((user.permissions.organizations === "w" ||
              user.permissions.contacts === "w") && ["Organizations"]) ||
              []),
            ...((user.permissions.regions === "w" && ["Regions"]) || []),
            ...((user.permissions.users === "w" && ["Users"]) || []),
            ...((user.permissions.permissions === "w" && ["Access Control"]) ||
              []),
            ...((user.permissions.templates === "w" && ["Templates"]) || []),
          ]
        : []),
    ];
  }

  return {
    menuList,
    path,
    basePath,
    homePath,
    adminPath,
  };
}

type IMenuPaths = {
  [key: string]: string;
};

const menuPaths: IMenuPaths = {
  "Time and Reports": ``,
  Tickets: `/tickets`,
  Settings: `/settings`,
  Downloads: `/downloads`,
  Employees: `/employees`,
  Organizations: `/organizations`,
  "Cost Codes": `/codes`,
  Regions: `/regions`,
  Users: `/users`,
  "Access Control": `/access`,
  Templates: "/templates",
};
