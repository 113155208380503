import * as React from "react";
import { useHistory } from "react-router";
import Button from "@mui/material/Button";
import { MenuButtonProps } from "../../App";

function LoginButton({ setMenuTitle }: MenuButtonProps) {
  const history = useHistory();
  return (
    <Button
      color="inherit"
      onClick={() => {
        setMenuTitle("");
        history.push("/login");
      }}
    >
      Login
    </Button>
  );
}

export default LoginButton;
